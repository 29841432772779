// 1. Import the initial variables
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/derived-variables";

// $slider-radius: 4rem;
$slider-track-background: $red !important; 
// $slider-track-radius: $radius;
$slider-track-border: 0px solid $red !important; 
$slider-track-shadow: 0px 0px 0px $red !important; 
// $slider-thumb-background: $white;
// $slider-thumb-radius: $radius;
$slider-thumb-border: 1px solid $red !important; 
// $slider-thumb-shadow: none;
// $slider-thumb-to-track-ratio: 2;
// $slider-output-width: 3rem;
$slider-output-background: $red !important; 
// $slider-output-radius: $radius;

@import  'bulma/css/bulma.min.css';
@import  'bulma-extensions/dist/css/bulma-extensions.min.css';

// :export {
// }