$tvMin: 1441px;
$widescreenMax: 1440px;
$widescreenMin: 1201px;
$desktopMax: 1200px;
$desktopMin: 1024px;
$tabletMax: 1023px;
$tabletMin: 768px;
$mobileMax: 767px;

:export {
    tvMin: $tvMin;
    widescreenMax: $widescreenMax;
    widescreenMin: $widescreenMin;
    desktopMax: $desktopMax;
    desktopMin: $desktopMin;
    tabletMax: $tabletMax;
    tabletMin: $tabletMin;
    mobileMax: $mobileMax;
}