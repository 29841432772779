$font-path-quick-bold: "/fonts/Quicksand/Quicksand-Bold.ttf"; 
$family-name-quick-bold: 'Quicksand';

$font-path-quick-regular: "/fonts/Quicksand/Quicksand-Regular.ttf";
$family-name-quick-regular: 'QuicksandRegular';

$font-path-quick-500: '/fonts/Quicksand/Quicksand-Variable-500.ttf';
$family-name-quick-500: 'Quicksand500';

@font-face {  
  font-family: $family-name-quick-bold;
  src: url($font-path-quick-bold);
  font-style: normal;
  font-weight: normal;
  font-display: normal;
}

@font-face {
  font-family: $family-name-quick-regular;
  src: url($font-path-quick-regular);
  font-style: normal;
  font-weight: normal;
  font-display: normal;
}

@font-face {
  font-family: $family-name-quick-500;
  src: url($font-path-quick-500);
  font-style: normal;
  font-weight: 500;
  font-display: auto;
}
