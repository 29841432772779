@import 'bulma.scss';

.switch[type=checkbox]:checked + label:before {
  background: $sky;
}   

.navbar-item img {
  max-height: 4rem !important;
}

.button {
  display: flex;
  align-items: center;
  font-family: $family-name-quick-bold;
  font-size: 1rem;
  background-image: linear-gradient(to right, #FFC796 0%, #FF6B95 50%, #A86AA4 100%);
  background-size: 200% auto;
  color: white !important;
  border-radius: 1rem !important; 
  border: none !important;
  text-shadow: 1px 1px 1px #00000040;
  transition: all 0.5s ease; 
  &:hover {
    background-position: right center; /* change the direction of the change here */
  }
  &.is-large {
    padding: 1rem 1.25rem !important;
    font-size: 1.5rem !important;
    border-radius: 1rem !important;
    &:hover {
      padding: 1rem 1.25rem !important;
    }
  }
  &.is-small {
    height: 1.75rem;
    padding: 0.25rem 0.625rem !important;
    font-size: 0.875rem !important;
    border-radius: 0.625rem !important;
    &:hover {
      padding: 0.25rem 0.625rem !important;
    }
  }
  &.with-icon {
    display: flex;
    gap: 0.25rem;
  }
  &.blue {
    cursor: pointer;
    font-family: $family-name-quick-bold;
    color: white !important;
    border: none !important;
    font-weight: bold;
    text-shadow: 2px 2px 2px #00000020;
    background: linear-gradient(to right, #47a8bd 0%, #2a6794 50%, #0c266a 100%);
    background-size: 200% auto;
    transition: all 500ms ease; 
    &:hover {
      background-position: right center !important; 
    }
    &:focus, :focus-visible, :focus-within, :active {
      background-position: right center !important; 
    }
  }
  &.paleblue {
    display: flex;
    align-items: center;
    text-shadow: none;
    gap: 0.25rem;
    padding: 2rem 2.5rem;
    color: $marine !important;
    background: $pale-blue;
    box-shadow: 7px 7px 20px #47a8bd33,
                -7px -7px 20px #ffffff,
                inset 0px 0px 0px #47a8bd33,
                inset 0px 0px 0px #ffffff;
    transition: all 100ms ease;
    &:hover {
      box-shadow: 0px 0px 0px #47a8bd33,
                  7px 7px 20px #ffffff,
                  inset 7px 7px 20px #47a8bd33,
                  inset -7px -7px 20px #ffffff;
      color: black !important;
      background: $pale-blue !important;
    }
  }
  &.is-loading {
    text-shadow: unset; 
    color: transparent !important;     
  }
}

input[name="password"] {
  padding-right: 3.25rem;
}

input {
  font-size: 1.5rem !important;
  &.input {
    min-width: 100%;
    background-color: white;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: $radius-large;
    border: 1px solid $black20; 
    color: $black60;
    transition: all 250ms ease;
    &::placeholder {
      color: $black20;
      transition: all 250ms ease;
    }
    &:active {
      border: 1px solid $black60;
      color: $black60;
      box-shadow: unset;
      &::placeholder {
        color: $black60;
      }
    }
    &:hover {
      border: 1px solid $black40;
      &::placeholder {
        color: $black40;
      }
    }
    &:focus-visible {
      box-shadow: unset;
    }
    &:focus {
      border: 1px solid $black60;
      color: $black60;
      outline-width: 0;
      box-shadow: unset;
      &::placeholder {
        color: $black60;
      }
    }
    &.is-danger {
      border: 1px solid $pink !important;
      background-color: $pale-burgundy !important;
    }
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
      background-color: transparent !important;
    }
  }
}

input[type=range] {
  &.slider {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }
  &.slider:not([orient=vertical]).has-output-tooltip + output {
    background: transparent !important;
    top: 2.75rem;
    color: $black60;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    width: fit-content;
    z-index: 10;
  }
  &::-webkit-slider-runnable-track {
    background: $pale-blue !important;
    height: 0.5rem;
  }
  &.slider::-webkit-slider-thumb {
    position: relative;
    border: 5px $sky solid;
    margin-top: -6px;
    background-color: white !important;
    height: 1.25rem;
    width: 1.25rem;    
  }
}

.select {
  height: fit-content !important;
  &:not(.is-multiple):not(.is-loading)::after {
    border-color: $black60; 
    border-width: 1.5px;
    outline-width: 0;
    top: 50%;
    transform: translateY(50%) rotate(-45deg);
    right: 1.25rem;
    height: 1.125rem;
    width: 1.125rem;  
  }
  select {
      min-width: 100%;
      width: 100%;
      background-color: white;
      border-radius: 15px;
      outline-width: 0;
      border: 1px solid $black20;
      color: $black60;
      font-size: 1.875rem;
      text-indent: -0.0625rem;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding-right: 3rem !important;
      transition: all 250ms ease;
      font-size: 1.5rem !important;        
      &.is-danger {
        border: 1px solid $pink !important;
        background-color: $pale-burgundy !important;
      }
      &::placeholder {
        color: black;
      }
      &:hover {
        outline-width: 0;
        border: 1px solid $black40;
        &::placeholder {
          color: $black40;
        }
      }
      &:focus {
        outline-width: 0;
        border: 1px solid $black60;
        box-shadow: unset;
        &::placeholder {
          color: $black60;
        }
      }
      &:active {
        outline-width: 0;
        box-shadow: unset;
        border: 1px solid $black60;
        &::placeholder {
          color: $black60;
        }
      }
      &:after {
        top: 50%;
        transform: translateY(-50%);
        right: 1.25rem;
      }
  }
}

textarea {
  resize: none;
  font-size: 1.5rem !important;
  border-radius: $radius-large !important;
  border: 1px solid $black20; 
  color: $black60;
  transition: all 250ms ease;
  &::placeholder {
    color: $black20;
    transition: all 250ms ease;
  }
  &:active {
    border: 1px solid $black60;
    color: $black60;
    box-shadow: unset;
    &::placeholder {
      color: $black60;
    }
  }
  &:hover {
    border: 1px solid $black40;
    &::placeholder {
      color: $black40;
    }
  }
  &:focus-visible {
    box-shadow: unset;
  }
  &:focus {
    border: 1px solid $black60;
    color: $black60;
    outline-width: 0;
    box-shadow: unset;
    &::placeholder {
      color: $black60;
    }
  }
  &.is-danger {
    border: 1px solid $pink !important;
    background-color: $pale-burgundy !important;
  }
}

label {
  font-weight: bold;
  white-space: nowrap;
}

.is-bold {
  font-family: $family-name-quick-bold !important; 
}

.title:not(:last-child) {
  margin-bottom: 0.5rem !important;
}

h1 {
  &.title {
    font-family: $family-name-quick-bold;
    font-size: 2.75rem;
    font-weight: bold;
    color: $marine;
    @media all and (max-width: $mobileMax) {
      font-size: 2.25rem;
      line-height: 1;
    }
  }
  &.luck-sky {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $sky;
    @media all and (max-width: $mobileMax) {
      font-size: 1.75rem;
      line-height: 1;
    }
  }
  &.luck-marine {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $marine;
    @media all and (max-width: $mobileMax) {
      font-size: 1.75rem;
      line-height: 1;
    }
  }
}

h2 {
  text-align: center;
  &.subtitle {
    font-family: $family-name-quick-bold;
    font-size: 2.5rem;
    font-weight: bold;
    color: $marine;
    @media all and (max-width: $mobileMax) {
      font-size: 1.5rem;
      line-height: 1;
    }
  }
  &.luck-sky {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $sky;
    @media all and (max-width: $mobileMax) {
      font-size: 1.75rem;
      line-height: 1;
    }
  }
  &.luck-marine {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $marine;
    @media all and (max-width: $mobileMax) {
      font-size: 1.5rem;
      line-height: 1;
    }
  }
}

h3 {
  font-size: 1.875rem;
  &.subtitle {
    font-family: $family-name-quick-bold;
    font-size: 1.875rem;
    font-weight: bold;  
    color: $marine;
    @media all and (max-width: $mobileMax) {
      font-size: 1.25rem;
      line-height: 1;
    }
  }
  &.luck-sky {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $sky;
    @media all and (max-width: $mobileMax) {
      font-size: 1.25rem;
      line-height: 1;
    }
  }
  &.luck-marine {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $marine;
    @media all and (max-width: $mobileMax) {
      font-size: 1.25rem;
      line-height: 1;
    }
  }
}

h4 {
  .subtitle {
    font-family: $family-name-quick-bold;
    font-size: 1.125rem;
    font-weight: bold;
  }
  &.luck-sky {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $sky;
  }
  &.luck-marine {
    font-family: $family-name-luck;
    font-size: 2.25rem;
    color: $marine;
  }
}
