@import  '../sass/screensVariables.scss';

//COLORS
$white: #FFF;
$burgundy: #BD1960;
$pale-burgundy: #FFF7FA;
$yellow: #F18B11;
$pale-yellow: #FFF9EF;
$blue: #1850DC;
$pale-blue: #F4F7FA;
$creme: #FFC796;
$sky: #47A8BD;

$marine: #0C266A;
$marine-light: #123CA5;
$marine-lighter: #1850DC;

$pale-grape: #FCF7FF;
$grape: #A71D9E;
$grape-light: #DA2FCF;
$grape-lighter: #E363DB;

$pink: #E03C6B;
$pink-light: #FF6591;
$pink-lighter: #FF9AB6;

$black20: #00000033;
$black40: #00000066;
$black60: #00000099;
$darkgrey: #302D2C;

//VARIABLES
$header-top-padding: 5.625rem;
$footer-height: 6.75rem;
$desktop-padding: 3rem 4.5rem;
$mobile-padding: 1rem;

//RADIUSES
$radius-small: 0.3125rem;
$radius: 0.625rem;
$radius-large: 1rem;
$radius-extra: 1.875rem;

//ASPECT-RATIO
$padding16x9: 56.25%;
$padding3x4: 131%;
$padding5x6: 120%;

//Z-INDEXES 
$zIndexOverlay: 500;
$zIndexModal: 501;

//OTHER
$weightBold: bold;
$color1: "";
$color2: "";

:export {
    black20: $black20;
    black40: $black40;
    black60: $black60;
    zIndexOverlay: $zIndexOverlay;
    zIndexModal: $zIndexModal;

    weightBold: $weightBold;

    headerMargin: $header-top-padding;
    footerHeight: $footer-height;
    mobilePadding: $mobile-padding;
    desktopPadding: $desktop-padding;

    burgundy: $burgundy;
    blue: $blue;
    yellow: $yellow;
    grape: $grape;
    paleburgundy: $pale-burgundy;
    paleyellow: $pale-yellow;
    palegrape: $pale-grape;
    color1: $color1;
    color2: $color2;
    radiusSmall: $radius-small;
    radius: $radius;
    radiusLarge: $radius-large;
    radiusExtra: $radius-extra;

    white: $white;
    paleblue: $pale-blue;
    creme: $creme;
    pink: $pink;
    grape: $grape;
    sky: $sky; 
    marine: $marine;
    darkgrey: $darkgrey;

    marineLight: $marine-light;
    marineLighter: $marine-lighter;

    grapeLight: $grape-light;
    grapeLighter: $grape-lighter;

    pinkLight: $pink-light;
    pinkLighter: $pink-lighter;

    tvMin: $tvMin;
    widescreenMax: $widescreenMax;
    widescreenMin: $widescreenMin;
    desktopMax: $desktopMax;
    desktopMin: $desktopMin;
    tabletMax: $tabletMax;
    tabletMin: $tabletMin;
    mobileMax: $mobileMax;
}