.toast-login {
  --toastify-color-progress-light: linear-gradient(101deg, #FFC796 0%, #FF6B95 50%, #A86AA4 100%) 0% 0% no-repeat padding-box;
}

.Toastify__toast-body {
  font-family: $family-name-quick-500;
  color: $darkgrey;
  text-align: center;
}

.Toastify__toast {
  border-radius: $radius !important;
}

.toast-logout{
  --toastify-color-progress-light: linear-gradient(101deg, #FFC796 0%, #FF6B95 50% ,#A86AA4 100%) 0% 0% no-repeat padding-box;
}

