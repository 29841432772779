@import  '../sass/fonts/quicksand.scss';
@import  '../sass/fonts/luckiestguy.scss';
@import  '../sass/bulma.scss';
@import  '../sass/variables.scss';
@import  '../sass/helpers.scss';
@import  '../sass/mouse.scss';
@import  '../sass/reset.scss';
@import  '../sass/toast.scss';
@import  '../sass/screensVariables.scss';
// @import  '../sass/bulma.scss';

html {
  width: auto;
  -webkit-tap-highlight-color: transparent;
}

body {
  width: 100%;
  min-width: 100%;
  margin: 0;
  font-family: $family-name-quick-500 !important; 
  color: $darkgrey;
}

header {
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

$rccs-size: 32rem;

@media all and (min-width: $tvMin) {
  .container {
    max-width: 80%;
  }
}